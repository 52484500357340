export enum ActiveFeatureFlags {
  ACCREDIBLE_AVG = 'accredible_avg',
  ACCREDISHARE_TRACKING = 'accredishare_tracking',
  ACCREDISHARE_WIDGET = 'accredishare_widget',
  BENCHPREP_INTEGRATION = 'benchprep_integration',
  BRIGHTSPACE_CUSTOM_ATTRIBUTES = 'brightspace_integration_custom_attributes',
  BRIGHTSPACE_INTEGRATION_LTI_V1P3 = 'brightspace_integration_lti_v1p3',
  CANVAS_INTEGRATION_LTI_V1P3 = 'canvas_integration_lti_v1p3',
  DEFAULT_CAMPAIGNS_RELEASE = 'default_campaigns_release',
  EMAIL_CAMPAIGN_DELIVERY_CONDITION = 'email_campaign_delivery_condition',
  PATHWAY_ENROLMENT = 'pathway_enrolment',
  PATHWAY_INFORMATION = 'pathway_information',
  PATHWAY_TRACKS = 'pathway_tracks',
  PRE_RENDER_GROUP_IMAGES = 'pre_render_group_images',
  PROFILE_COMPLETION_SIDEBAR = 'profile_completion_sidebar',
  SKILLJAR_CUSTOM_ATTRIBUTES = 'skilljar_integration_custom_attributes',
  SKILLJAR_NEW_UX = 'skilljar_integration_new_ux',
  SMARTRESUME_INTEGRATION = 'smartresume_integration',
  SMARTRESUME_INTEGRATION_SHARE_BUTTONS = 'smartresume_integration_share_buttons',
  SPOTLIGHT_BANNERS = 'spotlight_banners',
  SPOTLIGHT_SEARCH_ENHANCEMENT = 'spotlight_search_enhancement',
  V2_EMAIL_ANALYTICS = 'email_analytics_v2',
  EMAIL_EDITOR_GLOBAL_FONTS = 'email_editor_global_fonts',
  V2_EMAIL_EDITOR = 'v2_template_editor',
  ZAPIER_INTEGRATION = 'zapier_integration',
  V2_TRANSCRIPTS = 'v2_transcripts',
}

export type FeatureFlags = {
  [K in ActiveFeatureFlags]: boolean;
};
